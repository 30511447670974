(function () {
  "use strict";
  const navigation = new Navigation(document.getElementById("navigation"));

  function heroSlider() {
    if (document.querySelector('.hero-slider')) {
      const sliderTitles = document.querySelectorAll('.slide-content')
      sliderTitles[0].classList.add('active-title') // ilk slide'a aktif class ekliyoruz
      // console.log(sliderTitles)

      const heroSlider = new Swiper('.hero-slider .swiper', {
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
        },
        loop: true,
        navigation: {
          nextEl: ".hero-slider .slider-button-next",
          prevEl: ".hero-slider .slider-button-prev",
        },
        grabCursor: true,
        effect: "creative",
        creativeEffect: {
          prev: {
            shadow: true,
            translate: ["-20%", 0, -1],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        },
        on: {
          slideChangeTransitionStart: function () {
            // Get the current slide index
            var slideIndex = this.realIndex;
            console.log(slideIndex);

            sliderTitles.forEach((title, titleIndex) => {
              const index = titleIndex;
              if (index == slideIndex) {
                title.classList.add("active-title");
              } else {
                title.classList.remove("active-title");
              }
            });
          },
        },
      })
    }
  }


  // Uluslararası Telefon Input
  function telefonInput() {
    // Slider Altı Form
    if (document.querySelector('#telInput')) {
      var input = document.querySelector("#telInput");
      window.intlTelInput(input, {
        utilsScript: "assets/scripts/utils.js"
      });
    }
    // Modal Form Tel
    if (document.querySelector('#modalTel')) {
      var input = document.querySelector("#modalTel");
      window.intlTelInput(input, {
        utilsScript: "assets/scripts/utils.js"
      });
    }
  }

  // Kiralama Slider
  function kiralamaSlider() {
    if (document.querySelector('.kiralama-slider')) {
      const slider = new Swiper('.kiralama-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,

        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }
      })
    }

  }

  // Form Slider
  function formSlider() {
    if (document.querySelector('.form-slider')) {
      const slider = new Swiper('.form-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".form-slider .slider-button-next",
          prevEl: ".form-slider .slider-button-prev",
        },
      })
    }

  }

  // Blog Slider
  function blogSlider() {
    if (document.querySelector('.blog-slider')) {
      const slider = new Swiper('.blog-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          },
        }
      })
    }

  }

  // Deneyim Slider
  function testimonialSlider() {
    if (document.querySelector('.testimonial-slider')) {
      const testimonialSlider = new Swiper('.testimonial-slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 70,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }
      })
    }

  }

  function initAcc(elem, option) {
    document.addEventListener('click', function (e) {
      if (!e.target.matches(elem + ' .a-btn')) return;
      else {
        if (!e.target.parentElement.classList.contains('active')) {
          if (option == true) {
            var elementList = document.querySelectorAll(elem + ' .a-container');
            Array.prototype.forEach.call(elementList, function (e) {
              e.classList.remove('active');
            });
          }
          e.target.parentElement.classList.add('active');
        } else {
          e.target.parentElement.classList.remove('active');
        }
      }
    });
  }

  function singleImageSlider() {
    if (document.querySelector('[data-single-img-slider]')) {
      const slider = new Swiper('[data-single-img-slider] .swiper', {
        spaceBetween: 10,
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: "[data-single-img-slider] .slider-button-next",
          prevEl: "[data-single-img-slider] .slider-button-prev",
        },

      })
    }
  }

  // Scroll top Button

  function scrollTop() {
    const btnScrollTop = document.getElementById('scrollTop')
    document.addEventListener('scroll', () => {
      if (window.scrollY > 1000) {
        btnScrollTop.classList.remove('hide-btn');
      } else {
        btnScrollTop.classList.add('hide-btn');
      }
    })

    btnScrollTop.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    })
  }

  // Footer accordion
  const accBtn = document.querySelectorAll(".acdn");
  accBtn.forEach((el) => {
    el.addEventListener('click', function () {
      el.classList.toggle('active')
      const panel = el.nextElementSibling
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    })
  })

  function sss() {
    if (document.querySelector('.sss-section')) {
      var headers = document.querySelectorAll('.dropdown-container .header');

      for (var i = 0; i < headers.length; i++) {
        headers[i].addEventListener('click', openCurrAccordion);
      }

      //Use this as the callback if you would like multiple dropdowns to be open
      function openAccordion(e) {
        var parent = this.parentElement;
        var article = this.nextElementSibling;

        if (!parent.classList.contains('open')) {
          parent.classList.add('open');
          article.style.maxHeight = article.scrollHeight + 'px';
        }
        else {
          parent.classList.remove('open');
          article.style.maxHeight = '0px';
        }
      }

      //Use this as the callback if you would like no more than one dropdown to be open
      function openCurrAccordion(e) {
        for (var i = 0; i < headers.length; i++) {
          var parent = headers[i].parentElement;
          var article = headers[i].nextElementSibling;

          if (this === headers[i] && !parent.classList.contains('open')) {
            parent.classList.add('open');
            article.style.maxHeight = article.scrollHeight + 'px';
          }
          else {
            parent.classList.remove('open');
            article.style.maxHeight = '0px';
          }
        }
      }
    }
  }



  heroSlider()
  // telefonInput()
  kiralamaSlider()
  formSlider()
  blogSlider()
  testimonialSlider()
  // initAcc('.accordion.v1', true);
  singleImageSlider()
  sss()
  // scrollTop()

})();